
import { useState } from 'react';

import { Page, Grid, Keyboard, Spacer, Capacity, Text } from '@geist-ui/core';
import TextInput from '../TextInput';

function MainPage() {
    const [pageText, setPageText] = useState('TRY THE APP!\nHighlight any word and click to search it on Google.\n\nHOW TO USE\n1. To get started, paste your desired text or a short paragraph into the area below our navigation bar on our app.\n2. Once your content is loaded, hover over any word within the text.\n3. Click on the word to trigger a Google search in a new tab, providing additional information and insights.\n\nOVERRIDING CONTENT\nSimply paste new text into the designated area.');
    const [wordCount, setWordCount] = useState(0);
    
    return(
      <Page.Content 
                  className="Content" 
                  onPaste={(e) => {
                    setPageText(e.clipboardData.getData('Text'));
                  }}
        >
          {/* <div>Press <Keyboard ctrl mr="10px">V</Keyboard> or <Keyboard option mr="10px">V</Keyboard> to paste text.</div>
           */}
           <Grid.Container>
              <Grid xs={12} justify='left'>
                  <div className='Instructions'>
                    <Text className='Text-Instructions'>Press</Text>
                    <Keyboard ctrl style={{display:'inline-block', margin: '5px'}}>V</Keyboard>
                    <Text className='Text-Instructions'>or</Text>
                    <Keyboard option style={{display:'inline-block', margin: '5px'}}>V</Keyboard>
                    <Text className='Text-Instructions'>to paste text below the line.</Text></div>
              </Grid>
              <Grid xs={12} justify='right' alignItems='center'>
                  <Text style={{display:'inline-block'}} className='Text-Capacity'>Text Length: </Text>
                  <Capacity style={{display:'inline-block', background: '#BBB'}} value={wordCount/8192.0*100} />
              </Grid>
              <Grid xs={24} style={{ background: '#444', borderRadius: '15px', marginBottom: '30px' }}>
                <Spacer h={0.25}/>
              </Grid>
          </Grid.Container>
          <TextInput 
            textInputProp={pageText}
            onTextCount={(count)=>{setWordCount(count)}}
          /> 
        </Page.Content>
    );

}


export default MainPage;

