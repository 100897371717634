
import React from 'react';
import { Page, Text, Grid } from '@geist-ui/core';

function AboutPage() {

  return (
    <Page.Content className='Content'>
        <Grid.Container gap={2} justify="center">
            <Grid xs={24} md={18}>
                <Text>
                    This app was developed as a handy solution for avid word explorers! Tailored for those who frequently find themselves googling unfamiliar words. If you ever struggle to decipher the meaning of English words while immersed in your reading, look no further. Simply paste your text on the home page and embark on an instant adventure of uncovering word meanings. We hope this app is useful to you!
                </Text>
            </Grid>
        </Grid.Container>
    </Page.Content>
  );
}

export default AboutPage;