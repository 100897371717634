import { useRouteError } from "react-router-dom";
import { Page, Text, Grid } from '@geist-ui/core';

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Page.Content className='Content'>
        <Grid.Container gap={2} justify="center">
            <Grid xs={24} md={18} style={{justifyContent:'center'}}>
                <Text h1>
                    Woah!
                </Text>
            </Grid>
            <Grid xs={24} md={18} style={{justifyContent:'center'}}>
                <Text p>
                    Sorry, an unexpected error has occurred.
                </Text>
            </Grid>
            <Grid xs={24} md={18} style={{justifyContent:'center'}}>
                <Text small i>
                    {error.statusText || error.message}
                </Text>
            </Grid>
        </Grid.Container>
    </Page.Content>
  );
}

export default ErrorPage;