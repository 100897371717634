import './App.css';
import { Outlet, Link, useLocation  } from "react-router-dom";
import { GeistProvider, CssBaseline } from '@geist-ui/core'
import { Page } from '@geist-ui/core';

function App() {
  let location = useLocation(); 
  return (

      <div className="App">
        <GeistProvider>
          <CssBaseline />
               <Page>
                <Page.Header>
                  <nav>
                      <label className="logo">MAKE.ME.WORD.WISE.</label>
                      <ul>
                          <li><Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} to={`/`}>HOME</Link></li>
                          <li><Link className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} to={`/about`}>ABOUT</Link></li>
                      </ul>
                  </nav>
                </Page.Header>
                <Outlet />
              </Page>
        </GeistProvider>
      </div>
  );
}

export default App;
