import React, { useState, useEffect } from 'react';
import { Text } from '@geist-ui/core';

function TextInput(props) {

    const [truncatedText, setTruncatedText] = useState([]);
    const { textInputProp, onTextCount } = props;

    useEffect(() => {  

        function truncateRawInputText(text) {
            const inputText = text.slice(0, 8192);
            const truncateTextInput = inputText.split(/([\n]+)/g);
            onTextCount(inputText.length);
            setTruncatedText(truncateTextInput);
        }

        if(textInputProp !== "" && textInputProp !== " " && textInputProp !== null && textInputProp !== undefined){
            truncateRawInputText(textInputProp);
        }
    }, [textInputProp, onTextCount]);

    return(
        <React.Fragment>
            {truncatedText.map((textChunk, index) => {
                if(/^(\n)+$/.test(textChunk)){
                    return(
                    <React.Fragment key={'fragment' + Math.random().toString(36).slice(2) + index  + 'div'}>
                        <br key={textChunk.toString() + Math.random().toString(36).slice(2) + index + 'br1'}/>
                        <br key={textChunk.toString() + Math.random().toString(36).slice(2) + index + 'br2'}/>
                    </React.Fragment>
                    );
                }
                else {
                    const formatedText = textChunk.split(/([\w]+)/g).map((word, wordIndex) => {
                        if(/^(\n)$/.test(word)){
                            return(
                                <br key={'new-line' + Math.random().toString(36).slice(2) + index + wordIndex + 'break'} />
                            )
                        }
                        else if(/\s/.test(word)){
                            return(
                                <Text key={"space-symbol" + Math.random().toString(36).slice(2) + index + wordIndex + 'space'} className="Text-Symbol">{word}</Text>
                            )
                        }
                        else if(/^([^a-zA-Z0-9'\\-])+$/.test(word)){
                            return <Text key={'other-symbols' + Math.random().toString(36).slice(2) + wordIndex + index + 'symbol'} className="Text-Symbol">{word}</Text>;
                        }
                        else {
                            return(
                                <Text
                                    key={word.toString() + Math.random().toString(36).slice(2) + wordIndex + index + 'main'}
                                    className="Text-Input"
                                >
                                    <a 
                                        key={Math.random().toString(36).slice(2) + word.toString() + wordIndex + index + 'link'}
                                        target="_blank"
                                        rel="noreferrer" 
                                        href= {
                                            "https://www.google.com/search?q=" + word.replace(/[\W_]+/g,"") + "+definition"
                                        }
                                    >
                                        {word}
                                    </a>
                                </Text>
                            );
                        }
                    })
                    return formatedText;
                } 
            })}       
        </React.Fragment>
    );
};

export default TextInput;